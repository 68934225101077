$primary: #DD2D2D;
$gray: #555;
$gray-light: #777;
$line-height-base: 1.6;
$font-size-base: 0.875rem;
$font-weight-bolder: bold;
$body-bg: #ffffff;
$code-color: #586E75;
$pre-color: #586E75;
$code-font-size: 90%;
$well-bg: #f8f8f8;
$pre-bg: #f8f8f8;
$breadcrumb-bg: #ffffff;
$breadcrumb-padding-x: 0.0rem;
$breadcrumb-active-color: #000000;
$navbar-brand-font-size: 30px;
$navbar-brand-padding-y: 0.0rem;
$navbar-light-color: $gray-light;
$navbar-padding-y: 0.0rem;
$nav-pills-link-active-color: #555;
$nav-pills-link-active-bg: #e7e7e7;
$nav-pills-border-radius: 0px;

h1, .h1 { font-size: floor($font-size-base * 2.50); } /* ~34px */
h2, .h2 { font-size: floor($font-size-base * 2.15); } /* ~30px */
h3, .h3 { font-size: ceil($font-size-base * 1.70); }  /* ~24px */
h4, .h4 { font-size: ceil($font-size-base * 1.25); }  /* ~18px */
h5, .h5 { font-size: $font-size-base; }
h6, .h6 { font-size: ceil($font-size-base * 0.85); }  /* ~12px */

// 20px gutter
.reduced-gutter.row {
  margin-right: -10px;
  margin-left: -10px;
}
.reduced-gutter {
  padding-right: 10px;
  padding-left: 10px;
}

/* typekit */

.wf-loading {
    visibility: hidden;
}
.wf-active {
	visibility: visible;
}

/* general */

.container {
  padding-left: 25px;
  padding-right: 25px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 300;
	/* Museo */
}

.center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.text-center {
  text-align: center;
}

// also modify Bootstrap source in _reboot.scss
b, strong {
  font-weight: bold;
}

/* logo */

.logo {
	width: 50px;
	height: 50px;
}

.biglogo {
	width: 170px;
	height: 170px;
	padding: 5px;
}

.logobox {
	height: 170px;
	display: table-cell;
	vertical-align: middle;
}

/* navbar */

.navbar-brand {
	font-size: 30px;
	font-weight: 100;
  color: $gray-light;
	/* Museo */
}
.navbar-nav {
	font-size: 30px;
	font-weight: 100;
	/* Museo */
}
.navbar-text {
	font-size: 15px;
	font-weight: 300;
	/* Museo */
}

.bg-navbar {
  background: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
}

.nav-brand a {
  color: $gray;
}

.nav-brand a:hover {
  color: $primary;
  text-decoration: none;
}

.nav-link a {
  color: $gray;
}

.nav-link a:hover {
  color: $primary;
  text-decoration: none;
}

.navbar-toggler {
  margin-top: 14px;
  margin-bottom: 14px;
}

/* headings */

.bigtitle {
	font-size: 60px;
	line-height: 55px;
	font-weight: 100;
	/* Museo */
}

.title {
	font-size: 36px;
	line-height: 40px;
	font-weight: 100;
	/* Museo */
}

.bighead {
	font-size: 32px;
	font-weight: 300;
	line-height:36px;
	/* Museo */
}

.head {
	font-size: 24px;
	font-weight: 300;
	line-height:30px;
	/* Museo */
}

.smallhead {
	font-size: 19px;
	font-weight: 300;
	line-height: 23px;
	/* Museo */
}

.note {
	font-size: 15px;
	font-weight: 300;
	line-height: 18px;
	/* Museo */
}

.smallnote {
	font-size: 13px;
	font-weight: 300;
	line-height: 16px;
	/* Museo */
}

.text-gray {
	color: $gray-light;
}

.text-dark-gray {
	color: $gray;
}

.text-figure-legend {
  font-weight: 700;
  font-size: 13px;
}

/* other */

body {
	font-weight: 300;
}

p {
	font-weight: 300;
}

.hugespacer {
	height: 50px;
}

.bigspacer {
	height: 20px;
}

.spacer {
	height: 10px;
}

.smallspacer {
	height: 5px;
}

a.off {
	color: inherit;
}

pre {
    overflow-x: auto;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 10px;
}

pre code {
    overflow-wrap: normal;
    white-space: pre;
}

table {
	@extend .table;
	@extend .table-striped;
}

img {
	@extend .img-fluid;
}

img.offset {
	float: right;
	margin: 12px 0px 10px 15px;
}

img.avatar {
	width: 20px;
}

.post img {
  float: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.post iframe {
  padding-top: 10px;
  padding-bottom: 20px;
}

blockquote {
	@extend .text-gray;
  border-left: 5px solid #e7e7e7;
	p {
    font-size: ($font-size-base * 1.00);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    }
}

.feedbox {
    height: 320px;
    overflow: hidden;
	-webkit-mask-image: -webkit-gradient(linear, left 85%, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
/*	-webkit-box-shadow: inset 0 -30px 10px -10px white; */
/*	   -moz-box-shadow: inset 0 -30px 10px -10px white; */
/*	        box-shadow: inset 0 -30px 10px -10px white; */
}

.postbox {
	min-height: 80px;
}

.paperbox {
	min-height: 140px;
}

.projectbox {
	min-height: 120px;
}

.memberbox {

}

.talkbox {
	min-height: 100px;
}

.titlebox {
	min-width: 300px;
}

.handlebox {
	height: 20px;
	display: table-cell;
	vertical-align: middle;
}

.pad-right {
	padding-right: 10px;
}

.pad-left {
	padding-left: 10px;
}

#footer {
	height: 80px;
}

.footernav {
	@extend .note;
	@extend .text-dark-gray;
	text-align: center;
	margin-right: 2px;
}

.centered-pills {
    text-align: center
}
.centered-pills ul.nav-pills {
    display: inline-block
}
.centered-pills li {
    display: inline
}
.centered-pills a {
    float: left
}
* html .centered-pills ul.nav-pills, *+html .centered-pills ul.nav-pills {
    display: inline
}

/* Infinite scroll */

.infinite-spinner {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: #333;
  margin: 60px auto;

  -webkit-animation: scaleout 1.0s infinite ease-in-out;
  animation: scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
  0% { -webkit-transform: scale(0.0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes scaleout {
  0% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 100% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}
